<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-banner food-banner">
      <div class="items">
        <div class="item">
          <span v-html="$t('food-menu.main_page')"></span>

          <picture v-if="user.class === 'c' || user.class === 'ss'">
            <source
              srcset="@/assets/img/food-hero-business-m.jpg"
              media="(max-width: 1023px)"
            />
            <source
              srcset="@/assets/img/food-hero-business-s.jpg"
              media="(max-width: 767px)"
            />
            <img
              src="@/assets/img/food-hero-business.jpg"
              width="100%"
              height="100%"
              alt=""
            />
          </picture>
          <picture v-else>
            <source
              srcset="@/assets/img/food-hero-econom-m.jpg"
              media="(max-width: 1023px)"
            />
            <source
              srcset="@/assets/img/food-hero-econom-s.jpg"
              media="(max-width: 767px)"
            />
            <img
              src="@/assets/img/food-hero-econom.jpg"
              width="100%"
              height="100%"
              alt=""
            />
          </picture>
        </div>
      </div>
    </section>

    <section
      class="section section-localnav"
      v-if="user.class === 'c' || user.class === 'ss'"
    >
      <nav class="tabnav">
        <ul class="tabnav-items">
          <li class="tabnav-offset-item"><div></div></li>
          <li
            class="tabnav-item tabnav-first-item"
            :class="{ active: !alcohol }"
          >
            <em></em>
            <router-link :to="{ name: 'Menu' }" class="tabnav-link">{{
              $t("food-menu.main")
            }}</router-link>
          </li>
          <li class="tabnav-item tabnav-last-item" :class="{ active: alcohol }">
            <em></em>
            <router-link
              :to="{ name: 'Menu', params: { alcohol: 'alcohol' } }"
              class="tabnav-link"
              active-class="active"
              >{{ $t("food-menu.wine") }}</router-link
            >
          </li>
          <li class="tabnav-offset-item"><div></div></li>
        </ul>
      </nav>
    </section>

    <section
      class="section food-menu"
      v-if="!alcohol && items.food && items.food.length"
    >
      <div class="container m-container-only-s">
        <div class="page-description">
          {{ $t("food-menu.description") }}
        </div>

        <form>
          <div
            class="eating-time"
            v-for="(item, index) in items.food.find(
              it =>
                it.class ===
                (user.class === 'c' || user.class === 'ss'
                  ? 'business'
                  : 'economy')
            ).rations"
            :key="index"
          >
            <figure>
              <img
                v-bind:src="
                  !!item.image
                    ? 'data:image/jpeg;base64,' + item.image
                    : require('@/assets/img/business-dinner.jpg')
                "
                alt=""
              />
              <figcaption>{{ item.title }}</figcaption>
            </figure>

            <div
              class="item"
              v-for="(section, iSection) in item.sections"
              :key="iSection"
            >
              <span
                v-if="
                  !section.selective ||
                    (user.class !== 'c' && user.class !== 'ss')
                "
              >
                <span class="caption">{{ section.title }}</span>
                <span v-for="(it, idx) in section.items" :key="idx">
                  <strong>{{ it.title }}</strong>
                  <p class="descr" v-if="it.description">
                    {{ it.description }}
                  </p>
                </span>
              </span>

              <span class="parent" v-else>
                <span class="caption">{{ section.title }}</span>
                <div class="dl" v-for="(it, idx) in section.items" :key="idx">
                  <div class="dt">
                    <div
                      :class="
                        section.selective === 1
                          ? 'input-radio'
                          : 'input-checkbox'
                      "
                    >
                      <input
                        :name="iSection + '' + item.id"
                        :disabled="orderExist(item.id)"
                        :checked="
                          orderExist(item.id) &&
                            ((selectedFoodMenu[item.id] &&
                              selectedFoodMenu[item.id].find(
                                menu => menu === it.id
                              )) ||
                              (orderExist(item.id).selectMenuFood[item.id] &&
                                orderExist(item.id).selectMenuFood[
                                  item.id
                                ].find(menu => menu === it.id)))
                        "
                        @change="
                          selectedFoodMenuAction(
                            it.id,
                            section.items,
                            section.selective === 1
                              ? 'custom-radio'
                              : 'checkbox',
                            item.id,
                            $event
                          )
                        "
                        type="checkbox"
                        :value="it.id"
                        :id="it.title"
                      />
                      <span
                        class="checkmark"
                        :class="{ disabled: orderExist(item.id) }"
                      ></span>
                    </div>
                  </div>
                  <div class="dd">
                    <label :for="it.title">
                      <strong
                        :class="{
                          disabled:
                            orderExist(item.id) &&
                            !(
                              (selectedFoodMenu[item.id] &&
                                selectedFoodMenu[item.id].find(
                                  menu => menu === it.id
                                )) ||
                              (orderExist(item.id).selectMenuFood[item.id] &&
                                orderExist(item.id).selectMenuFood[
                                  item.id
                                ].find(menu => menu === it.id))
                            )
                        }"
                        >{{ it.title }}</strong
                      >
                      <p class="descr" v-if="it.description">
                        {{ it.description }}
                      </p>
                    </label>
                  </div>
                </div>
              </span>
            </div>

            <div
              class="item buttons"
              v-if="user.class === 'c' || user.class === 'ss'"
            >
              <input
                type="button"
                class="button"
                :disabled="orderExist(item.id)"
                :class="{
                  'disabled-custom': orderExist(item.id)
                }"
                @click="saveMenuOrder(item.id)"
                :value="
                  orderExist(item.id)
                    ? $t('food-menu.save_complete')
                    : $t('food-menu.save')
                "
              />
            </div>
          </div>
        </form>

        <div class="thanks" v-if="user.class === 'c' || user.class === 'ss'">
          {{ $t("food-menu.complete") }}
        </div>
      </div>
    </section>
    <section
      class="section food-menu"
      v-if="alcohol && items.food && items.food.length"
    >
      <div class="container m-container-only-s">
        <div
          class="page-description"
          v-html="$t('food-menu.wine_description')"
        ></div>

        <template v-for="(itemAlc, indexAlc) in uniqueAlcType">
          <div
            class="menu-block"
            v-for="(item, index) in items.alcohol.filter(
              it => it.contentType === itemAlc
            )"
            :class="{
              'last-item':
                !uniqueAlcType[indexAlc + 1] &&
                !items.alcohol.filter(it => it.contentType === itemAlc)[
                  index + 1
                ]
            }"
            :key="`menu_${itemAlc}_${index}`"
          >
            <h2>{{ item.name }}</h2>

            <span v-if="itemAlc === 'wine'">
              <div class="grid" v-for="(it, idx) in item.elements" :key="idx">
                <div class="col-img">
                  <img
                    style="width: 100%;"
                    v-bind:src="'data:image/jpeg;base64,' + it.image"
                    alt=""
                  />
                </div>
                <div class="col-info">
                  <strong>{{ it.title }}</strong>
                  <dl>
                    <dl
                      v-for="(spec, specIdx) in it.specifications"
                      :key="specIdx"
                    >
                      <dt>{{ spec.title }}</dt>
                      <dd>{{ spec.description }}</dd>
                    </dl>

                    <dt>
                      {{ it.description }}
                    </dt>
                  </dl>
                </div>
              </div>
            </span>
            <span v-else-if="itemAlc === 'drinks'">
              <div class="item">
                <strong
                  v-for="(it, idx) in item.elements"
                  :key="`${itemAlc}_${idx}`"
                  >{{ it.description }}
                </strong>
              </div>
            </span>
            <span v-else>
              <div
                class="item"
                v-for="(it, idx) in item.elements"
                :key="`${itemAlc}_${idx}`"
              >
                <span v-if="itemAlc === 'drinks'">
                  <strong
                    v-for="(it, idx) in item.elements"
                    :key="`${itemAlc}_${idx}`"
                    >{{ it.description }}
                  </strong>
                </span>
                <span v-else-if="itemAlc === 'spirits'">
                  <span class="caption">{{ it.title }}</span>
                  <strong>{{ it.description }}</strong>
                </span>
                <span v-else>
                  <strong>{{ it.title }}</strong>
                  <p class="descr">{{ it.description }}</p>
                </span>
              </div>
            </span>
          </div>
        </template>

        <div class="thanks"></div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Food_menu",
  title() {
    return this.$t("pages.food");
  },
  props: ["alcohol"],
  data: () => {
    return {
      items: {},
      selectedFoodMenu: {}
    };
  },
  computed: {
    ...mapState("user", {
      userState: state => state.user
    }),
    user() {
      if (this.items.food) {
        let business = this.items.food.find(it => it.class === "business");
        if (business && business.rations) {
          return this.userState;
        } else if (
          this.userState.class === "c" ||
          this.userState.class === "ss"
        ) {
          return { ...this.userState, class: "st" };
        }
      }
      return this.userState;
    },
    ...mapState({
      locale: state => state.locale
    }),
    ...mapState("order", {
      selectedFoodMenuState: state => state.selectedFoodMenu
    }),
    uniqueAlcType() {
      let distinct = [];
      let uniqueKeys = {};
      for (let i = 0; i < this.items.alcohol.length; i++) {
        if (!uniqueKeys[this.items.alcohol[i].contentType]) {
          uniqueKeys[this.items.alcohol[i].contentType] = true;
          distinct.push(this.items.alcohol[i].contentType);
        }
      }
      return distinct;
    }
  },
  watch: {
    async locale() {
      const {
        data: { menu }
      } = await this.axios.get("/api/foodMenu");
      this.items = menu;
    }
  },
  beforeRouteLeave(to, from, next) {
    document.documentElement.classList.remove("dark");
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      if (to.query.json) {
        const {
          data: { menu }
        } = await vm.axios.get(to.query.json);
        vm.items = menu;
      } else {
        const {
          data: { menu }
        } = await vm.axios.get("/api/foodMenu");
        vm.items = menu;
      }
      let business = vm.items.food.find(
        it =>
          it.class ===
          (vm.userState.class === "c" || vm.userState.class === "ss"
            ? "business"
            : "economy")
      );
      if (
        business &&
        business.rations &&
        (vm.userState.class === "c" || vm.userState.class === "ss")
      ) {
        document.documentElement.classList.add("dark");
      }
    });
  },
  methods: {
    orderExist(ration) {
      return this.selectedFoodMenuState.find(it => it.selectMenuFood[ration]);
    },
    ...mapActions("order", {
      setOrderFoodMenu: "setOrderFoodMenu"
    }),
    mutateKeyLang(key) {
      return key + "_" + this.$i18n.locale;
    },
    saveMenuOrder(ration) {
      if (this.orderExist(ration)) {
        return;
      }
      if (!this.selectedFoodMenu[ration]) {
        this.selectedFoodMenu[ration] = [];
      }

      let result = [];
      const mapMenu = this.items.food
        .find(
          it =>
            it.class ===
            (this.user.class === "c" || this.user.class === "ss"
              ? "business"
              : "economy")
        )
        .rations.find(it => it.id === ration);
      for (let iS = 0; iS < mapMenu.sections.length; iS++) {
        for (let iSi = 0; iSi < mapMenu.sections[iS].items.length; iSi++) {
          if (mapMenu.sections[iS].selective === 0) {
            result.push({
              name: mapMenu.sections[iS].items[iSi].title,
              id: mapMenu.sections[iS].items[iSi].id
            });
          } else if (
            this.selectedFoodMenu[ration] &&
            this.selectedFoodMenu[ration].find(
              itt => itt === mapMenu.sections[iS].items[iSi].id
            )
          ) {
            result.push({
              name: mapMenu.sections[iS].items[iSi].title,
              id: mapMenu.sections[iS].items[iSi].id
            });
          }
        }
      }
      this.setOrderFoodMenu({
        selected: this.selectedFoodMenu,
        items: result,
        ration
      });
    },
    selectedFoodMenuAction(item, items, type, ration, event) {
      if (!this.selectedFoodMenu[ration]) {
        this.selectedFoodMenu[ration] = [];
      }

      if (type === "custom-radio") {
        for (let i = 0; i < items.length; i++) {
          const findIndex = this.selectedFoodMenu[ration].findIndex(
            it => it === items[i].id && it !== item
          );
          if (findIndex !== -1) {
            Array.from(
              event.target
                .closest(".parent")
                .querySelectorAll("input[type=checkbox]")
            ).map(dom => (dom.checked = false));
            event.target.checked = false;
            this.selectedFoodMenu[ration].splice(findIndex, 1);
          }
        }
      }
      const findIndex = this.selectedFoodMenu[ration].findIndex(
        it => it === item
      );
      if (findIndex !== -1) {
        event.target.checked = false;
        this.selectedFoodMenu[ration].splice(findIndex, 1);
      } else {
        event.target.checked = true;
        this.selectedFoodMenu[ration].push(item);
      }
    }
  }
};
</script>

<style scoped>
.disabled-custom {
  color: black;
  font-size: 16px;
  background: #bababa;
  border-radius: 16px;
  text-align: center;
  width: 100%;
  padding: 8px 10px;
  border: none;
}
.input-radio input:checked ~ .checkmark.disabled:after {
  background: #8d8d8d;
}
.input-checkbox input:checked ~ .checkmark.disabled:after {
  background: url(../../scss/i/icon-check-gray.svg) center no-repeat;
}
.food-menu .eating-time .item strong.disabled {
  color: #8d8d8d;
}
</style>
